export const navbarConfig = [
  {
    text: "项目规范",
    link: "/docs/standard/java/java.md"
  },
  {
    text: "微服务开发手册",
    items: [
      {
        text: "微服务开发手册",
        link: "/docs/tutorial/quickstart/quickstart.md"
      },
      {
        text: "部署说明",
        link: "/docs/tutorial/docker/deploy.md"
      },
      {
        text: "版本升级",
        link: "/docs/tutorial/upgrade/otter-V5_0_16.md"
      },
      {
        text: "插件使用手册",
        link: "/docs/tutorial/plugins/lock.md"
      },
      {
        text: "设计方案",
        link: "/docs/tutorial/design/logs.md"
      },
      {
        text: "OSS 文件管理手册",
        link: "/docs/tutorial/design/ossfile.md"
      },
      {
        text: "Otter 用户使用手册",
        link: "/docs/userbookall/userbookall"
      },
      {
        text: "Otter 用户使用手册 分模块",
        link: "/docs/userbook/organization"
      },
      {
        text: "API接口文档",
        link: "/docs/tutorial/interfacedoc/security/versionPermission"
      }
    ]
  },
  {
    text: "技术分享",
    link: "/docs/techshare/oauth2"
  },
  {
    text: "前端开发手册",
    items: [
      {
        text: "通用规范",
        items: [
          {
            text: "前端开发规范",
            link: "https://cube.cndinfo.com/docs/guide/style-guide"
          }
        ]
      },
      {
        text: "Web 端文档",
        items: [
          {
            text: "Web 端设计规范",
            link: "https://web.cube.cndinfo.com/docs/resources/design"
          },
          {
            text: "微前端开发手册",
            link: "https://cube.cndinfo.com/docs/tutorial/develop/micro-frontends"
          },
          {
            text: "Web 组件库文档",
            link: "https://web.cube.cndinfo.com/"
          },
          {
            text: "Web Demo 演示",
            link: "https://web.cube.cndinfo.com/demo/login/login-temp1"
          }
        ]
      },
      {
        text: "移动端文档",
        items: [
          {
            text: "App 开发手册",
            link: "https://cube.cndinfo.com/docs/tutorial/develop/app"
          },
          {
            text: "移动端组件库文档",
            link: "https://mobile.cube.cndinfo.com/"
          },
          {
            text: "移动端 Demo 演示",
            link: "https://demo.mobile.cube.cndinfo.com"
          }
        ]
      },
      {
        text: "部署手册",
        items: [
          {
            text: "新平台 Web 部署手册",
            link: "https://cube.cndinfo.com/docs/tutorial/deploy/horizon"
          },
          {
            text: "App 发布手册",
            link: "https://cube.cndinfo.com/docs/tutorial/deploy/app"
          },
          {
            text: "H5 部署手册",
            link: "https://cube.cndinfo.com/docs/tutorial/deploy/h5"
          },
          {
            text: "小程序部署手册",
            link: "https://cube.cndinfo.com/docs/tutorial/deploy/mini-program"
          }
        ]
      }
    ]
  },
  {
    text: "生态",
    items: [
      {
        text: "AMS（通用APP管理后台）",
        link: "https://cube.cndinfo.com/docs/ecosystem/ams/manual"
      },
      {
        text: "演示地址",
        link: "https://otter.test.cndinfo.com"
      }
    ]
  },
  {
    text: "公共服务",
    items: [
      {
        text: "私有Maven仓库",
        link: "https://maven.devops.cndinfo.com"
      },
      {
        text: "git源码管理",
        link: "https://git.cndinfo.com"
      },
      {
        text: "Harbor",
        link: "https://harbor.devops.cndinfo.com"
      },
      {
        text: "私有npm仓库",
        link: "https://npm.devops.cndinfo.com"
      },
      {
        text: "项目管理",
        link: "https://jira.devops.cndinfo.com/"
      },
      {
        text: "easy-mock指南",
        link: "https://web.cube.cndinfo.com/docs/easy-mock/usage"
      }
    ]
  },
  {
    text: "更新日志",
    link: "/docs/changelog/rearend.md"
  }
]
